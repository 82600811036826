import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | RedClay Courts
			</title>
			<meta name={"description"} content={"Ваш найкращий тенісний досвід"} />
			<meta property={"og:title"} content={"Головна | RedClay Courts"} />
			<meta property={"og:description"} content={"Ваш найкращий тенісний досвід"} />
			<link rel={"shortcut icon"} href={"https://radiomintim.com/img/2103694.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://radiomintim.com/img/2103694.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://radiomintim.com/img/2103694.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://radiomintim.com/img/2103694.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://radiomintim.com/img/2103694.png"} />
			<meta name={"msapplication-TileImage"} content={"https://radiomintim.com/img/2103694.png"} />
			<meta name={"msapplication-TileColor"} content={"https://radiomintim.com/img/2103694.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="160px 0 170px 0" background="linear-gradient(180deg,rgba(5, 7, 33, 0.1) 0%,rgba(5, 7, 33, 0.1) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://radiomintim.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-8">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="#111"
					lg-padding="0px 0 0px 0"
				>
					RedClay Courts!
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#d4dae0"
					padding="0px 200px 0px 200px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Ми раді вітати вас у RedClay Courts - ідеальному місці для любителів тенісу, які шукають першокласний ігровий досвід. Наші надсучасні глиняні корти пропонують ідеальне поєднання продуктивності та комфорту, гарантуючи, що кожна гра буде одночасно приємною та змагальною. Незалежно від того, досвідчений ви гравець чи новачок, наші корти створені для того, щоб задовольнити всі ваші потреби та зробити час, проведений на корті, незабутнім.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="--color-orange"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border="2px solid #111"
						color="#111"
						hover-background="rgba(63, 36, 216, 0)"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
					>
						Зв'яжіться з нами
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="#170f06">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--orange" font="--lead" md-margin="0px 0px 20px 0px">
				Де кожна гра - це матч, який запам'ятається
				</Text>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
				Про нас
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
				У RedClay Courts ми пишаємося тим, що пропонуємо найкращі тенісні корти з глиняним покриттям. Наше прагнення до досконалості відображається в кожному аспекті нашого об'єкту, від ретельно доглянутих кортів до гостинної атмосфери. Ми розуміємо пристрасть і відданість, які тенісисти привносять у гру, і прагнемо створити середовище, яке підтримує і примножує їхню любов до тенісу.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" background="#170f06" quarkly-title="Description-11">
			<Override
				slot="SectionContent"
				flex-wrap="wrap"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
			/>
			<Box border-color="--color-light" width="100%" border-width="0px 0px 1px 0px" border-style="solid" />
			<Box width="100%" margin="20px 0px 0px 0px">
				<Text
					margin="0px 0px 0px 0px"
					font="--headline2"
					color="--light"
					letter-spacing="-.05em"
					lg-font="normal 400 70px/1.1em &quot;Inter&quot;, sans-serif"
					sm-font="normal 400 40px/1.1em &quot;Inter&quot;, sans-serif"
				>
					Чому варто обирати корти RedClay?
				</Text>
			</Box>
			<Box
				margin="30px 0px 30px 0px"
				lg-margin="56px 0px 30px 0px"
				md-display="none"
				width="33.333%"
				padding="20px 20px 20px 20px"
			/>
			<Box
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="30px 0px 30px 0px"
				lg-margin="56px 0px 30px 0px"
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
				Найвища якість покриття: 
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--light">
				Наші корти утримуються за найвищими стандартами, забезпечуючи стабільну та надійну ігрову поверхню.
				</Text>
			</Box>
			<Box
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="30px 0px 30px 0px"
				lg-margin="56px 0px 30px 0px"
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
				Професійні тренери: 
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--light">
				доступ до досвідчених тренерів, які допоможуть вам покращити свою гру, незалежно від того, чи ви новачок, чи просунутий гравець.
				</Text>
			</Box>
			<Box
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="30px 0px 50px 0px"
				lg-margin="56px 0px 30px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
				Комплексні зручності: 
				</Text>
				<Text font="--base" color="--light" margin="40px 0px 0px 0px">
				Насолоджуйтесь нашими комфортними лаунж-зонами, роздягальнями та професійним магазином для всіх ваших тенісних потреб.
				</Text>
			</Box>
			<Box
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="30px 0px 50px 0px"
				lg-margin="56px 0px 30px 0px"
			>
				<Text color="--light" margin="0px 0px 0px 0px" font="--headline3">
				Гнучкий розклад: 
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--light">
				Ми пропонуємо різноманітні варіанти бронювання кортів відповідно до вашого напруженого графіка, щоб ви могли грати тоді, коли вам буде найзручніше.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fe240cd81ee001f7dd8c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});